
import { defineComponent, ref, onMounted, computed } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import moment from 'moment';
import { IMedia } from '@/core/data/media';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import axios from 'axios';
import JwtService from '@/core/services/JwtService';

export default defineComponent({
	name: 'add-customer-modal',
	components: {
		QuillEditor,
		Datatable
	},
	setup(props, { emit }) {
		const imageArray = ref();
		const search = ref<string>('');
		const tableData = ref<Array<IMedia>>([]);
		const initMedia = ref<Array<IMedia>>([]);
		const imageformRef = ref<null | HTMLFormElement>(null);
		const selectImageModalRef = ref<null | HTMLElement>(null);
		const showUploadImageModal = ref<boolean>(false);
		const imageFormData = ref({
			name: '',
			file: ''
		});
		const tableHeader = ref([
			{
				name: 'Image',
				key: 'url',
				sortable: true
			},
			{
				name: 'Title',
				key: 'name',
				sortable: true
			},
			{
				name: 'Id',
				key: 'id',
				sortable: true
			},
			{
				name: 'created At',
				key: 'createdDateTime',
				sortable: true
			}
		]);

		const imageRules = ref({
			name: [
				{
					required: true,
					message: 'Name is required',
					trigger: 'change'
				}
			],
			file: [
				{
					required: true,
					message: 'Image File is required',
					trigger: 'change'
				}
			]
		});

		const submitImage = () => {
			if (!imageformRef.value) {
				return;
			}

			imageformRef.value.validate(valid => {
				if (valid) {
					const imageData = new FormData();
					imageData.append('file', imageArray.value, imageArray.value.name);
					axios
						.post(`CmsMedia/?name=${imageFormData.value.name}`, imageData, {
							headers: {
								Authorization: `Bearer ${JwtService.getToken()}`,
								'Content-Type': `multipart/form-data;`
							}
						})
						.then(({ data }) => {
							// console.log('create cms media response', data);
							getMediaList();
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								hideUploadImage();
							});
						})
						.catch(({ response }) => {
							console.log('create CMS Media error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const getMediaList = () => {
			ApiService.get(`CmsMedia`)
				.then(({ data }) => {
					tableData.value.splice(0, tableData.value.length, ...data);
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const setSelectedImage = row => {
			emit('setSelectedImage', row);
			hideModal(selectImageModalRef.value);
		};

		const showUploadImage = () => {
			showUploadImageModal.value = true;
		};

		const hideUploadImage = () => {
			showUploadImageModal.value = false;
		};

		onMounted(() => {
			getMediaList();
		});

		const onChangeImage = event => {
			imageArray.value = event.target.files[0];
		};

		const searchItems = () => {
			tableData.value.splice(0, tableData.value.length, ...initMedia.value);
			if (search.value !== '') {
				let results: Array<IMedia> = [];
				for (let j = 0; j < tableData.value.length; j++) {
					if (searchingFunc(tableData.value[j], search.value)) {
						results.push(tableData.value[j]);
					}
				}
				tableData.value.splice(0, tableData.value.length, ...results);
			}
		};

		const searchingFunc = (obj, value): boolean => {
			for (let key in obj) {
				if (
					!Number.isInteger(obj[key]) &&
					!(typeof obj[key] === 'object') &&
					!(typeof obj[key] === 'boolean')
				) {
					if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
						return true;
					}
				}
			}
			return false;
		};

		return {
			search,
			moment,
			tableData,
			imageRules,
			tableHeader,
			searchItems,
			submitImage,
			imageformRef,
			onChangeImage,
			imageFormData,
			showUploadImage,
			hideUploadImage,
			setSelectedImage,
			selectImageModalRef,
			showUploadImageModal
		};
	}
});
