
import { defineComponent, onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import NewCardModal from '@/components/modals/forms/NewCardModal.vue';
import ImageListModel from '@/components/modals/forms/ImageListModel.vue';

export default defineComponent({
	name: 'customer-details',
	components: {
		QuillEditor,
		NewCardModal,
		ImageListModel
	},
	setup() {
		const route = useRoute();
		const store = useStore();

		const selectedImage = ref();
		const enumerationDetails = ref({});
		const loading = ref<boolean>(false);
		const contentEditor = ref<any>(null);
		const descriptionEditor = ref<any>(null);
		const formRef = ref<null | HTMLFormElement>(null);

		const isEdit = route.params.id;

		const formData = ref({
			title: '',
			shortDescription: '',
			userLevels: '',
			published: true,
			public: true,
			content: '',
			overviewImage: '',
			author: ''
		});

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'change'
				}
			],
			shortDescription: [
				{
					required: true,
					message: 'Short Description is required',
					trigger: 'change'
				}
			],
			userLevels: [
				{
					required: true,
					message: 'User Role is required',
					trigger: 'change'
				}
			],
			author: [
				{
					required: true,
					message: 'Author is required',
					trigger: 'change'
				}
			],
			content: [
				{
					required: true,
					message: 'content is required',
					trigger: 'change'
				}
			]
		});

		const getArticleDetails = () => {
			ApiService.get(`Article/${route.params.id}`)
				.then(({ data }) => {
					formData.value = data;
					contentEditor.value.setHTML(data.content);
					descriptionEditor.value.setHTML(data.shortDescription);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const setSelectedImage = row => {
			formData.value = { ...formData.value, overviewImage: row.id };
			selectedImage.value = row;
		};

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}
			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;

					var data: any = formData.value;
					if (!isEdit) {
						data = { ...data, customerId: selectedCustomer.value };
					}
					Object.keys(data).forEach(key => {
						if (data[key] === null || data[key] === '') {
							delete data[key];
						}
					});

					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(
							`Article/${route.params?.id}?articleId=${route.params?.id}`,
							data
						);
					} else {
						delete data['id'];
						serviceType = ApiService.post(`Article`, data);
					}

					serviceType
						.then(({ data }) => {
							// console.log('create customer response', data);
							loading.value = false;
							Swal.fire({
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = { ...formData.value, ...data };
								} else {
									setSelectedImage(null);
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create Article error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const getEnumerationDetails = () => {
			ApiService.get(`EnumerationValues/e0268e53-84aa-4174-a950-1d3d988f11ce`)
				.then(({ data }) => {
					enumerationDetails.value = data;
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Article`, [
				'Articles'
			]);
			if (isEdit) {
				getArticleDetails();
			} else {
				getEnumerationDetails();
			}
		});

		return {
			rules,
			submit,
			isEdit,
			formRef,
			formData,
			selectedImage,
			contentEditor,
			setSelectedImage,
			descriptionEditor,
			enumerationDetails
		};
	}
});
